import { Box, Divider, Typography } from "@mui/material";
import * as React from "react";
import { useTranslation } from "react-i18next";

export default function PlaceOrderSuccess({ order }) {
  const { t } = useTranslation();
  return (
    <Box
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      p={4}
      flexDirection={"column"}
    >
      <Box
        component={"img"}
        src={`/assets/icons/order_status/${order?.status}.png`}
      />
      <Typography variant={"h2"} mt={2} textAlign={"center"}>
        {t(`order_status.order_${order?.status}`)}
      </Typography>

      <Typography variant={"p2"} mt={2} textAlign={"center"}>
        {t("layout.order_number")}: {order?.orderNum}
      </Typography>

      <Box
        sx={{
          mt: 2,
          pt: 2,
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography variant={"h1"} textAlign={"center"}>
          取餐編號: {order?.orderNum.slice(-4)}
        </Typography>
      </Box>
    </Box>
  );
}
