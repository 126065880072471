import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import { Base64 } from "js-base64";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { OrderApi } from "../api/OrderApi";
import Header from "../components/Header";
import OrderSkeleton from "../components/order/OrderSkeleton";
import PlaceOrderSuccess from "../components/order/PlaceOrderSuccess";
import { FormatHelper } from "../helper/FormatHelper";
import { getVariantName } from "../helper/UnitsHelper";
import palette from "../styles/theme/palette";

export default function OrderDetail() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [fullScreenLoading, setFullScreenLoading] = useState(false);
  const [order, setOrder] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const { orderNum } = useParams();

  useEffect(() => {
    console.log("orderNum", orderNum);
    fetchOrderDetail();
  }, [orderNum]);

  const fetchOrderDetail = async () => {
    try {
      setFullScreenLoading(true);
      const encodedOrderNum = decodeURIComponent(Base64.decode(orderNum));
      const res = await OrderApi.findOrderByOrderNum(encodedOrderNum);
      console.log("res", res);
      setOrder(res);
      setFullScreenLoading(false);
    } catch (error) {
      console.log("error", error);
      // toast.error(error.message);
    } finally {
      // setFullScreenLoading(false);
    }
  };

  return (
    <Box>
      <Header disableBack disableHome title={t("layout.receipt")} />

      {fullScreenLoading ? (
        <OrderSkeleton />
      ) : (
        <Box>
          <Box
            sx={{
              backgroundColor: palette.background.paper,
            }}
          >
            <PlaceOrderSuccess order={order} />
            <Button
              sx={{ mb: 1 }}
              fullWidth
              onClick={() => {
                fetchOrderDetail();
              }}
            >
              {t("layout.refresh")}
            </Button>
          </Box>

          <Box
            sx={{
              mt: 2,
              p: 2,
              backgroundColor: palette.background.paper,
              display: "flex",
              flexDirection: "column",
              gap: 1,
            }}
          >
            <Typography variant="h3">
              {order?.status === "pending"
                ? t("takeaway.pickup_time")
                : t("takeaway.confirm_time")}
              : {order?.pickupTime}
            </Typography>
            <Typography variant="body2">
              {t("takeaway.pickup_time_notes")}
            </Typography>
          </Box>

          <Box
            sx={{
              mt: 2,
              p: 2,
              backgroundColor: palette.background.paper,
            }}
          >
            {order?.items.map((item, index) => (
              <Grid key={index} container spacing={1} pt={4}>
                <Grid item xs={2}>
                  <Typography variant={"h4"}> {item.qty} x</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Box display={"flex"} flexDirection={"column"} gap={1}>
                    <Typography variant={"h4"}>
                      {item.product && item.product.title}
                    </Typography>
                    <Typography variant={"p2"} color={palette.text.secondary}>
                      {getVariantName(item)}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant={"h4"} textAlign={"end"}>
                    {FormatHelper.formatAmount(item.finalPrice)}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Divider sx={{ pb: 2 }} />
                </Grid>
              </Grid>
            ))}

            <Box display={"flex"} justifyContent={"space-between"} pt={2}>
              <Typography variant="h3">{t("layout.total")}</Typography>

              <Typography variant="h3">
                {FormatHelper.formatAmount(order?.finalTotal || 0)}
              </Typography>
            </Box>
          </Box>

          <Box
            sx={{
              mt: 2,
              p: 2,
              backgroundColor: palette.background.paper,
            }}
          >
            <Typography variant="h3">
              我們已收到你的訂單，如有需要我們會跟據閣下的電話號碼聯絡您。
            </Typography>
            <Divider sx={{ py: 1 }} />
            <Box sx={{ pt: 1, display: "flex", flexDirection: "row", gap: 1 }}>
              <Typography variant="h4">{t("takeaway.name")}:</Typography>
              <Typography variant="p1">{order?.customerName}</Typography>
            </Box>
            {/* <Box sx={{ pt: 1, display: "flex", flexDirection: "row", gap: 1 }}>
              <Typography variant="h4">{t("takeaway.email")}:</Typography>
              <Typography variant="p1">{order?.customerEmail}</Typography>
            </Box> */}
            <Box sx={{ pt: 1, display: "flex", flexDirection: "row", gap: 1 }}>
              <Typography variant="h4">{t("takeaway.phone")}:</Typography>
              <Typography variant="p1">{order?.customerPhone}</Typography>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
}
